import { useContext ,useState} from "react";
import Logo from "../assets/logo.png"
import  {ThemeContext} from "../theme/ThemeContext"





const Footer = () => {
  const { currentTheme, switchTheme } = useContext(ThemeContext);




  return (

      <div className="flex items-center text-center justify-center  w-full p-4" >
    
        <img onClick={switchTheme} src={Logo} className="h-12  border rounded-lg bg-black " alt="logo"></img>

      
    </div>
  );
};

export default Footer;
