
export const themes = {
    light: {
      primaryColor: 'white',
      secondaryColor: 'black',
    },
    dark: {
      primaryColor: 'black',
      secondaryColor: 'white',
    }
  };

