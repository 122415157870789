import React from "react";
import { FaAppStoreIos } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import './shadow.css'; // Make sure this path is correct

const Works = () => {
  return (
    <div className="flex flex-row items-center justify-around p-4 gap:2 md:gap-4 w-full max-w-4xl">
      <div className="flex flex-1  py-4 md:py-6">
        <a
          href="https://www.apple.com/app-store/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col w-full h-full items-center justify-center text-center p-4 gap-10"
        >
          <FaAppStoreIos
            className=" w-[120px] md:w-[160px] h-[140px] md:h-[180px] p-1 animate-move-shadow-ios rounded-xl text-blue-500"
          />
          <div className="flex flex-col w-fit border p-2 rounded-md border-blue-600">
            <h3 className="text-sm font-bold text-blue-500">IOS Apps</h3>
          </div>
        </a>
      </div>

      <div className="flex flex-1  py-8">
        <a
          href="https://play.google.com/store/apps/developer?id=H+Skywater"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col w-full h-full items-center justify-center text-center p-4 gap-10"
        >
          <DiAndroid
            className="w-[120px] md:w-[160px] h-[140px] md:h-[180px] p-1 animate-move-shadow-android rounded-xl text-green-500 "
          />
          <div className="flex flex-col w-fit border p-2 rounded-md border-green-600">
            <h3 className="text-sm font-bold text-green-500">Android Apps</h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Works;
