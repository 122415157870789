import React from "react";
import { SiGithub, SiFigma, SiExpo, SiXcode, SiApple, SiWindows, SiFirebase, SiPostman, SiAndroidstudio, SiSqlite } from "react-icons/si"; // Added SiSqlite for SQLite
import { DiJavascript1, DiPhotoshop } from "react-icons/di";
import { FaReact, FaSwift } from "react-icons/fa";
import './shadow.css'; // Make sure this path is correct

const Skills = () => {
  return (
    <div className="flex py-4  h-20 md:h-30 w-[896px] overflow-hidden  border-gray-700  border-b border-t ">
      <div className="flex flex-row items-center justify-center animate-scroll w-full gap-4 md:gap-8 ">
       
        <DiPhotoshop className=" text-4xl   text-blue-800" />
        <SiGithub className=" text-4xl   text-gray-500" />
        <SiFigma className=" text-4xl   text-pink-600" />
        <SiExpo className=" text-4xl   text-gray-500" />
        <SiXcode className=" text-4xl   text-blue-400" />
        <SiWindows className=" text-4xl   text-blue-600" />
        <SiApple className=" text-4xl   text-gray-800" />
        <FaSwift className=" text-4xl   text-orange-500" />
        <FaReact className=" text-4xl   text-blue-500" />
        <DiJavascript1 className=" text-4xl   text-yellow-500" />
        <SiAndroidstudio className=" text-4xl   text-green-500" />

        <SiFirebase className="  text-4xl   text-yellow-400" />
        <SiPostman className=" text-4xl   text-orange-500" />
        <SiSqlite className=" text-4xl  text-green-500" /> {/* Added SQLite icon */}
      </div>
    </div>
  );
};

export default Skills;
