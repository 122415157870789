import { useContext, useState } from "react";

const AboutMe = () => {
  return (
    <div className="flex flex-row items-center justify-center  px-6 py-3 border-gray-700 border-b w-full max-w-4xl text-md ">
      <h1 className="">
      Hi, I'm Halil. I'm a Mobile App & Software Entrepreneur with a degree in Public Relations and Advertising. 
      I have one year of experience as a Software Developer at Revature and have been freelancing since 2022,
       focusing on mobile applications and educational mini-games. I utilize Swift for iOS development and 
       React Native with Expo for Android, integrating Firebase and APIs for real-time functionality, as well as SQLite for database management.
      </h1>
    </div>
  );
};

export default AboutMe;
