import Header from "./components/Header";
import Works from "./components/Works";
import Footer from "./components/Footer";
import { Suspense, useState } from "react";
import { ThemeContext } from "./theme/ThemeContext";
import { themes } from "./theme/themes";
import "react-toastify/dist/ReactToastify.css";
import AboutMe from "./components/AboutMe";
import Skills from "./components/Skills";

function App() {
  const [currentTheme, setCurrentTheme] = useState(themes.dark);
  const switchTheme = () => {
    setCurrentTheme(currentTheme === themes.light ? themes.dark : themes.light);
  };

  return (
    <Suspense fallback={"Loading..."}>
      <div
        style={{
          backgroundColor: currentTheme.primaryColor,
          color: currentTheme.secondaryColor,
        }}
        className=" flex-1 justify-center items-center w-full   "
      >
        <ThemeContext.Provider value={{ currentTheme, switchTheme }}>
          <div className=" flex flex-col flex-1 justify-center items-center  w-full h-full   font-mono  overflow-hidden py-10 md:py-12 px-2 ">
           
            <Header />
            <AboutMe />
            <Works />
            <Skills />

            <Footer />
          </div>
        </ThemeContext.Provider>
      </div>
    </Suspense>
  );
}

export default App;
